<template>
    <div class="topic-region-page">
        <HeroBasic
            :heading="title"
            :eyebrow="pageType"
            accentColor="grey"
            class="page-hero topic-region"
            :showTopographic="true"
            :hasHeaderOffset="true"
        >
            <template #headerDetails>
                <RichTextElements v-if="description" :elements="description" bodyStyle="serif-large" class="dek" />
                <div class="social-share">
                    <ShareButton />
                    <CopyLinkButton />
                </div>
            </template>
        </HeroBasic>

        <client-only>
            <search
                :page-language="pageLanguage"
                :index-name="`content_${pageLanguage}`"
                :enabled-filters="{ topics: true, regions: true, years: true, experts: true }"
                :filter="`collection != 'collections'`"
                :preselected-filters="[id]"
                has-filters
                :hideResultCount="true"
            >
                <template #results="{ nextPage, results }">
                    <template v-if="results?.length">
                        <template v-if="!nextPage || nextPage === 2">
                            <div class="featured-result" v-if="onlyDefaultFilters">
                                <ContentCardBlock
                                    :related="transformContentSearchIndexToContentCardBlockProps(results[0])"
                                    orientation="wide"
                                    size="100"
                                    :isProportionate="true"
                                    class="result"
                                    context="inline"
                                    :hideDateCard="true"
                                    :showPublicationDate="true"
                                    :pageLanguage="pageLanguage"
                                    new-search
                                />
                            </div>

                            <div class="featured-results-wrapper" v-if="onlyDefaultFilters">
                                <ContentCardBlock
                                    v-if="results[1]"
                                    :related="transformContentSearchIndexToContentCardBlockProps(results[1])"
                                    orientation="wide"
                                    size="33"
                                    :isProportionate="true"
                                    class="result"
                                    context="inline"
                                    :hideDateCard="true"
                                    :showPublicationDate="true"
                                    :pageLanguage="pageLanguage"
                                    new-search
                                />

                                <ContentCardBlock
                                    v-if="results[2]"
                                    :related="transformContentSearchIndexToContentCardBlockProps(results[2])"
                                    orientation="wide"
                                    size="33"
                                    :isProportionate="true"
                                    class="result"
                                    context="inline"
                                    :hideDateCard="true"
                                    :showPublicationDate="true"
                                    :pageLanguage="pageLanguage"
                                    new-search
                                />

                                <ContentCardBlock
                                    v-if="results[3]"
                                    :related="transformContentSearchIndexToContentCardBlockProps(results[3])"
                                    orientation="wide"
                                    size="33"
                                    :isProportionate="true"
                                    class="result"
                                    context="inline"
                                    :hideDateCard="true"
                                    :showPublicationDate="true"
                                    :pageLanguage="pageLanguage"
                                    new-search
                                />
                            </div>

                            <div class="secondary-results">
                                <template v-for="(result, index) in results">
                                    <ContentCardBlock
                                        v-if="index > 3 || !onlyDefaultFilters"
                                        :key="index"
                                        :related="transformContentSearchIndexToContentCardBlockProps(result)"
                                        orientation="wide"
                                        context="inline"
                                        class="result"
                                        :hideDateCard="true"
                                        :showPublicationDate="true"
                                        :pageLanguage="pageLanguage"
                                        new-search
                                    />
                                </template>
                            </div>
                        </template>

                        <template v-else>
                            <div class="secondary-results">
                                <ContentCardBlock
                                    v-for="(result, index) in results"
                                    :key="index"
                                    :related="transformContentSearchIndexToContentCardBlockProps(result)"
                                    orientation="wide"
                                    context="inline"
                                    class="result"
                                    :hideDateCard="true"
                                    :showPublicationDate="true"
                                    :pageLanguage="pageLanguage"
                                    new-search
                                />
                            </div>
                        </template>
                    </template>
                </template>
            </search>
        </client-only>
    </div>
</template>

<script setup>
import transformContentSearchIndexToContentCardBlockProps from '@/utils/search/transformContentSearchIndexToContentCardBlock';
const { pageLanguage } = usePageDataStore();
const props = defineProps({
    id: {
        type: String,
    },
    regions: {
        type: Array,
        default: () => [],
    },
    title: {
        type: String,
    },
    description: {
        type: Object,
    },
    type: {
        type: String,
        default: '',
    },
    pageType: {
        type: String,
    },
    contentType: {
        type: String,
        default: '',
    },
    locale: {
        type: String,
        default: '',
    },
});

const defaultFilters = computed(() => {
    return {
        ...(props.pageType === 'region' && {
            regions: [props.id],
        }),
        ...(props.pageType === 'topic' && {
            topics: [props.id],
        }),
    };
});

const onlyDefaultFilters = ref(true);
function onFilterChanged(payload) {
    if (!Object.keys(payload)?.length || payload === defaultFilters.value) {
        onlyDefaultFilters.value = true;
    } else if (payload !== defaultFilters.value) {
        onlyDefaultFilters.value = false;
    }
}

const headerAttributesStore = useHeaderAttributesStore();

const { currentCenter } = useCenters();
const currentCenterSlug = currentCenter.value.slug;
let GTMCenter = 'CEIP';
if (currentCenterSlug.length) {
    if (currentCenterSlug === 'china') {
        GTMCenter = 'Carnegie China';
    } else if (currentCenterSlug === 'europe') {
        GTMCenter = 'Carnegie Europe';
    } else if (currentCenterSlug === 'russiaEurasia') {
        GTMCenter = 'Carnegie Russia Eurasia';
    } else if (currentCenterSlug === 'india') {
        GTMCenter = 'Carnegie India';
    } else if (currentCenterSlug === 'middleEast') {
        GTMCenter = 'Carnegie Middle East Center';
    }
}
const GTMLang = props.locale;
onMounted(() => {
    window.dataLayer.push({
        Center: GTMCenter,
        Doctype: 'Topic Region Page',
        Expert: '',
        'Taxonomy Tags Program': '',
        'Taxonomy Special Project': '',
        'Taxonomy Tags Regions': '',
        'Taxonomy Tags Topics': '',
        'Page Language': GTMLang,
        event: 'new_data_layer',
    });
    headerAttributesStore.updateHeaderStyleAttributes({ background: 'transparent' });
});
</script>

<style lang="scss" scoped>
.social-share {
    margin-top: vertical-space(1);
    display: flex;
    gap: 0.4rem;
    align-items: center;
}

.featured-result {
    .result {
        @include media-query(full) {
            border-bottom: none;
        }
    }
}

.featured-results-wrapper {
    display: grid;
    grid-template-columns: 1fr;

    @include media-query(full) {
        gap: var(--col-gutter);
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    .result {
        @include media-query(full) {
            margin-top: vertical-space(2);
            margin-bottom: vertical-space(1);
            border-bottom: none;
        }
    }
}

.result {
    border-bottom: 1px solid palette(border-light);
}

.secondary-results {
    width: 100%;
    max-width: 93.5rem;
}
</style>
